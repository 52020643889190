//
// entry point
//

window.thumbnailRendererPromise = (async () => {
    const { init } = await import(/* webpackMode: "eager" */ "js/firebase/init");
    await init();

    await import(/* webpackMode: "eager" */ "js/preload");

    await import(/* webpackMode: "eager" */ "legacy-js/renderer/renderer"); // <-- entry point
})();
